import React, {
  useState,
} from 'react';

import {
  useParams
} from 'react-router-dom';

import formulae from './Formulae.json';
import { useStore } from './Store'

function Warning(props) {
  const variation = props.variation || 'warning';

  return props.show ? (
    <div className={"alert alert-" + variation}>
      <div className="flex-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="w-8 h-8 mx-2 stroke-current"> 
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>                         
        </svg> 
        <div>
          {props.children}
        </div>
      </div>
    </div>
  ) : <></>;
}

function Formula() {
  const { id } = useParams();

  const ca = useStore(state => state.ca);
  const weight = useStore(state => state.weight);

  const [rate, setRate] = useState(Math.floor(((formulae[id].rateLow + formulae[id].rateHigh)/2)/ca));
  const [volume, setVolume] = useState(Math.floor((((formulae[id].volumeLow + formulae[id].volumeHigh)/2)*weight)/(ca/1000)));

  const handleRateChange = (evt) => {
    setRate(parseFloat(evt.target.value));
  };

  const handleVolumeChange = (evt) => {
    setVolume(parseFloat(evt.target.value));
  };

  return (
    <div className="flex flex-1 flex-col gap-3">
      <div className="mx-auto p-3 w-full flex justify-around font-semibold text-lg text-secondary">
        <span>Konzentration: {ca}mg/ml</span>
        <span>Gewicht: {weight}kg</span>
      </div>
      <div className="shadow-lg card bg-base-100">
        <div className="card-body menu">
          <div className="flex justify-between">
            <label className="card-title text-primary" htmlFor="rate-input">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eyedropper inline mr-2" viewBox="0 0 16 16">
                <path d="M13.354.646a1.207 1.207 0 0 0-1.708 0L8.5 3.793l-.646-.647a.5.5 0 1 0-.708.708L8.293 5l-7.147 7.146A.5.5 0 0 0 1 12.5v1.793l-.854.853a.5.5 0 1 0 .708.707L1.707 15H3.5a.5.5 0 0 0 .354-.146L11 7.707l1.146 1.147a.5.5 0 0 0 .708-.708l-.647-.646 3.147-3.146a1.207 1.207 0 0 0 0-1.708l-2-2zM2 12.707l7-7L10.293 7l-7 7H2v-1.293z"/>
              </svg>
              Injektionsrate
            </label>
            <span className="text-accent font-bold">{rate.toFixed(1)} ml/s</span>
          </div>
          <span className="text-xs text-neutral">Die minimale und maximal wählbare Injektionsmenge, ergibt sich aus den Angaben in der Leitlinie</span>
          <div className="flex justify-between items-center">
            <span>{ (formulae[id].rateLow/ca).toFixed(1) }</span>
            <input className="m-2 flex-1 range range-accent" type="range" id="rate-input" name="rate-input" min={formulae[id].rateLow/ca} value={rate} max={formulae[id].rateHigh/ca} step="0.1" onChange={handleRateChange} />
            <span>{ (formulae[id].rateHigh/ca).toFixed(1) }</span>
          </div>
        </div>
      </div>

      <div className="shadow-lg card bg-base-100">
        <div className="card-body">
          <div className="flex justify-between">
            <label className="card-title text-primary" htmlFor="volume-input">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-droplet-half inline mr-2" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M7.21.8C7.69.295 8 0 8 0c.109.363.234.708.371 1.038.812 1.946 2.073 3.35 3.197 4.6C12.878 7.096 14 8.345 14 10a6 6 0 0 1-12 0C2 6.668 5.58 2.517 7.21.8zm.413 1.021A31.25 31.25 0 0 0 5.794 3.99c-.726.95-1.436 2.008-1.96 3.07C3.304 8.133 3 9.138 3 10c0 0 2.5 1.5 5 .5s5-.5 5-.5c0-1.201-.796-2.157-2.181-3.7l-.03-.032C9.75 5.11 8.5 3.72 7.623 1.82z"/>
              </svg>
              Kontrastmittel-Volumen
            </label>
            <span className="text-accent font-bold">{volume} ml</span>
          </div>
          <span className="text-xs text-neutral">Das minimale und maximal wählbare Kontrastmittel-Volumen, ergibt sich aus den Angaben in der Leitlinie</span>
          <div className="flex justify-between items-center">
            <input className="m-2 flex-1 range range-accent" type="range" id="volume-input" name="volume-input" min={((formulae[id].volumeLow*weight)/(ca/1000)).toFixed(1)} value={volume} max={((formulae[id].volumeHigh*weight)/(ca/1000)).toFixed(1)} step="0.1" onChange={handleVolumeChange} />
          </div>
        </div>
      </div> 

      <div className="divider text-neutral">Daraus ergibt sich</div> 

      <div className="shadow-lg card bg-base-100">
        <div className="card-body">
          <div className="flex justify-between">
            <span className="card-title text-primary" >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-stopwatch inline mr-2" viewBox="0 0 16 16">
                <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z"/>
                <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z"/>
              </svg>
              Injektionsdauer
            </span>
            <span className="m-2 text-center text-accent font-bold">{(volume/rate).toFixed(1)} s</span>
          </div>
        </div>
      </div>

      <Warning show={(ca*volume)/1000 > 45}>
        <span className="font-bold">Maximale Jodmasse überschritten!</span>
      </Warning>

      <Warning show={rate > 5} variation="error">
        <span className="block font-bold">Warnung!</span>
        <span className="block text-sm mb-2">Injektionsraten &gt;5ml/s sind mit einem deutlich erhöhten Risiko eines Paravasats vergesellschaftet und werden nicht empfohlen.</span>
        <span className="block font-semibold">Möglicher Ausweg:</span>
        <span className="text-sm">Kontrastmittel mit höherer Jod-Konzentration</span>
      </Warning>
    </div>
  );
}

export default Formula;
