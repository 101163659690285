import create from "zustand"
import { persist } from "zustand/middleware"

export const useStore = create(persist(
  (set, get) => ({
    ca: 200,
    setCa: (ca) => set(state => ({ ca: ca })),
    weight: 50,
    setWeight: (weight) => set(state => ({ weight: weight })),
  }),
  {
    name: 'kmsherpa-store',
    getStorage: () => localStorage,
  }
));
