import React, { useState, useEffect } from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import PWAPrompt from 'react-ios-pwa-prompt'

import Start from './Start'
import Formula from './Formula'
import formulae from './Formulae.json';

import QRCode from 'qrcode';

import { ReactComponent as Info } from './info-circle.svg';
import { ReactComponent as DGMTRLogo } from './dgmtr-logo.svg';
// import './App.css';

function QR() {
  useEffect(() => {
    const canvas = document.getElementById('qr-code');
    QRCode.toCanvas(canvas, window.location.href, function (error) {
        if (error) console.error(error)
    });
  }, [] );
  return (
    <canvas id="qr-code"></canvas>
  );
}

function Title() {
  const { id } = useParams();
  const formula = formulae[id];
  return (
    <>{ formula ? formula.name : <></> }</>
  );
}

function App() {
  const [modal, setModal] = useState(false);
  const [disclaimer, setDisclaimer] = useState(true);

  return (
    <>
      <div id="disclaimer-modal" className={`modal modal-bottom sm:modal-middle ${ disclaimer ? ' modal-open' : '' }`}>
        <div className="prose modal-box">
          <span className="block mb-2 text-lg font-bold text-primary">Disclaimer</span>
          <p>
            Diese App dient ausschließlich als Berechnungshilfe. Für die Kontrastmittelapplikation trägt der Anwender die volle Verantwortung.
          </p>
          <p>
            Berechnungshilfe auf Grundlage der&nbsp;
            <a className="hober-pointer underline font-semibold text-blue-800" href="https://www.bundesaerztekammer.de/themen/aerzte/qualitaetssicherung/richtlinien-leitlinien-empfehlungen-stellungnahmen" target="blank">
              Leitlinie der Bundesärztekammer zur Qualitätssicherung in der Computertomographie
            </a>.
          </p>
          <div className="flex flex-wrap justify-start">
            <DGMTRLogo className='max-h-24' />
            <img src="drg-logo.png" />
          </div>
          <div className="modal-action">
            <button onClick={() => setDisclaimer(false)} className="btn btn-primary">
              Zur Kenntnis genommen
            </button>
          </div>
        </div>
      </div>
      <div id="info-modal" className={`modal${ modal ? ' modal-open' : '' }`}>
        <div className="prose modal-box">
          <p className="text-lg font-bold mb-2">Diese App dient ausschließlich als Berechnungshilfe. Für die Kontrastmittelapplikation trägt der Anwender die volle Verantwortung.</p>
          <hr className="mb-2" />
          <div className="flex flex-wrap justify-start mb-4">
            <DGMTRLogo className='max-h-24' />
            <img src="drg-logo.png" />
          </div>
          <p className="font-bold">&copy; Dt. Röntgengesellschaft</p>
          <p className="mb-2">Umsetzung Priv. Doz. Dr. med. Sebastian Reinartz und <a href="https://sebastianfedrowitz.com" className="text-primary">Sebastian Fedrowitz</a></p>
          <QR />
          <p className="flex flex-wrap gap-2">
            <a href="mailto:kmsherpa@sebastianfedrowitz.com" className="inline-flex items-center gap-1 text-primary">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="inline bi bi-envelope" viewBox="0 0 16 16">
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
              </svg> Fragen und Anregungen?
            </a>
	    <a className="text-primary" href="https://dgmtr.de/km-sherpa/impressum/" target="_blank">Impressum</a>
	    <a className="text-primary" href="https://dgmtr.de/km-sherpa/datenschutz/" target="_blank">Datenschutzerklärung</a>
          </p>
          <div className="modal-action">
            <button onClick={() => setModal(!modal)} className="btn btn-primary">
              Ok
            </button>
          </div>
        </div>
      </div>
      <Router>
        <div className="mb-4 border-b navbar">
          <div className="container max-w-xl mx-auto">
            <Route path="/formula/:id">
              <div className="flex-none">
                <Link to="/">
                  <button className="btn btn-square btn-ghost">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current text-accent">            
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>              
                    </svg>
                  </button>
                </Link>
              </div> 
            </Route>
            <div className="flex-1 px-2 mx-2 text-neutral">
              <span className="text-lg font-bold">
                <Route exact path="/">
                  <span className="text-primary">KM Sherpa</span>
                </Route>
                <Route path="/formula/:id">
                  <span className="text-primary"><Title /></span>
                </Route>
              </span>
            </div>
            <Info onClick={() => setModal(true)} />
          </div>
        </div>
        <div className="container flex flex-col flex-1 max-w-xl mx-auto">
          <Switch>
            <Route path="/formula/:id"
            render={
              (props) => <Formula {...props} title={`Props through render`} />
            } />
            <Route path="/" component={ Start } />
          </Switch>
        </div>
        <PWAPrompt debug={false} />
      </Router>
    </>
  );
}

export default App;
