import React from 'react';

import {
  Link
} from 'react-router-dom';

import { useStore } from './Store'

import formulae from './Formulae.json';

const concentrations = [150, 200, 250, 300, 320, 350, 370, 400];

function Start() {
  const ca = useStore(state => state.ca);
  const setCa = useStore(state => state.setCa);
  const weight = useStore(state => state.weight);
  const setWeight = useStore(state => state.setWeight);

  const handleCaChange = (evt) => setCa(evt.target.value);
  const handleWeightChange = (evt) => setWeight(evt.target.value);

  return ( 
    <div className="flex flex-col flex-1 gap-3">
      <div className="shadow-lg card bg-base-100">
        <div className="card-body">
          <div className="flex items-center justify-between">
            <label className="card-title text-primary" htmlFor="ca-input">Kontrastmittel</label>
            <select className="select" id="ca-input" name="ca-input" onChange={handleCaChange} value={ca}>
              { concentrations.map((c,i) => <option key={i} value={c}>{c} mg/ml</option>) }
            </select>
          </div>
        </div>
      </div>

      <div className="shadow-lg card bg-base-100">
        <div className="card-body">
          <div className="flex justify-between">
            <label className="card-title text-primary" htmlFor="ca-input">Körpergewicht</label>
            <span className="m-2 text-center">{weight} kg</span>
          </div>
          <input className="flex-1 m-2 range range-accent" type="range" id="weight-input" name="weight-input" min="40" value={weight} max="150" onChange={handleWeightChange} />
        </div>
      </div>

      <ul className="py-3 shadow-lg menu bg-base-100 rounded-box">
        <li className="text-sm text-neutral">
          <span>
            Protokolle (entsprechen der Kapitel in der Leitlinie)
          </span>
        </li>
        { formulae.map((f,i) => <li key={i}><Link to={`/formula/${i}`}><span className="mr-2 font-bold text-primary">{f.hieroglyph}</span> <span className="text-neutral">{f.name}</span></Link></li>) }
      </ul>
      <span className="p-3 mx-auto mt-auto">&copy; 2021&ndash;2023 Dt. Röntgengesellschaft</span>
    </div>
  );
}

export default Start;
